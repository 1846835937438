exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-couples-js": () => import("./../../../src/pages/gallery/couples.js" /* webpackChunkName: "component---src-pages-gallery-couples-js" */),
  "component---src-pages-gallery-decorations-js": () => import("./../../../src/pages/gallery/decorations.js" /* webpackChunkName: "component---src-pages-gallery-decorations-js" */),
  "component---src-pages-gallery-flowers-js": () => import("./../../../src/pages/gallery/flowers.js" /* webpackChunkName: "component---src-pages-gallery-flowers-js" */),
  "component---src-pages-gallery-gallery-layout-js": () => import("./../../../src/pages/gallery/galleryLayout.js" /* webpackChunkName: "component---src-pages-gallery-gallery-layout-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-wardrobe-js": () => import("./../../../src/pages/gallery/wardrobe.js" /* webpackChunkName: "component---src-pages-gallery-wardrobe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-videos-decorations-js": () => import("./../../../src/pages/videos/decorations.js" /* webpackChunkName: "component---src-pages-videos-decorations-js" */),
  "component---src-pages-videos-dresses-js": () => import("./../../../src/pages/videos/dresses.js" /* webpackChunkName: "component---src-pages-videos-dresses-js" */),
  "component---src-pages-videos-events-js": () => import("./../../../src/pages/videos/events.js" /* webpackChunkName: "component---src-pages-videos-events-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-videos-video-layout-js": () => import("./../../../src/pages/videos/videoLayout.js" /* webpackChunkName: "component---src-pages-videos-video-layout-js" */)
}

