/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import { LanguageContextProvider } from "./src/context/languageContextProvider"
// highlight-start
export const wrapRootElement = ({ element }) => (
  <LanguageContextProvider>{element}</LanguageContextProvider>
)
